module.exports = [{
      plugin: require('/Users/berikiushi/Dev/vcomedia/rockstar-gears5/rockstar-gears5/frontend/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-4417490-4"},
    },{
      plugin: require('/Users/berikiushi/Dev/vcomedia/rockstar-gears5/rockstar-gears5/frontend/node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/berikiushi/Dev/vcomedia/rockstar-gears5/rockstar-gears5/frontend/src/messages/","languages":["en-US","en-CA","en-GB","de-DE","es-MX"],"defaultLanguage":"en-US","redirect":true},
    },{
      plugin: require('/Users/berikiushi/Dev/vcomedia/rockstar-gears5/rockstar-gears5/frontend/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
